import { numberToPrettyString } from '@cian/utils';

import { flatMap } from 'lodash';

import { ISearchOffersData } from '../../api/api';
import { IJsonQuery } from '../../api/models/json_query';
import { IOfferSerialized } from '../../api/models/offer_preview';
import { parseOfferDetail } from '../../redux/offer/response_parser/offer_parser';
import declension from '../../utils/declension';
import { IOfferInfo } from '../offer/model';

import { IGk, parseGks } from './gk';
import { IGkSource, IOfferSource, IPage } from './model';
import { parseOffers, parseUrlParams } from './offer';

function makeGkSource(gk: IGk, isSuggestion: boolean): IGkSource {
  return {
    type: 'gk',
    key: String(Math.random()),
    isSuggestion,
    data: gk,
  };
}

function makeOfferSource(offer: IOfferInfo, isSuggestion: boolean, isCommercialPageList: boolean): IOfferSource {
  offer.isByHomeowner = offer.baseInfo.fromHomeOwnerLabel;
  offer.rosreestrCheck = offer.baseInfo.rosreestrCheck;

  return {
    type: 'offer',
    key: String(Math.random()),
    isSuggestion,
    isCommercialPageList,
    data: offer,
  };
}

interface IParsePageOptions {
  isSuggestionPage: boolean;
  isCommercialPageList: boolean;
  isGkPage: boolean;
  pageNumber: number;
}

export function parsePage(
  data: ISearchOffersData,
  { isCommercialPageList, isSuggestionPage, isGkPage, pageNumber }: IParsePageOptions,
): IPage {
  const offers = parseOffers(data).map(offer => makeOfferSource(offer, isSuggestionPage, isCommercialPageList));
  const gks = parseGks(data).map(gk => makeGkSource(gk, isSuggestionPage));
  const extraTop3 = data.top3IsOn;

  return {
    num: pageNumber,
    offers: isSuggestionPage ? [] : offers,
    gks: isSuggestionPage ? [] : gks,
    suggestions: isSuggestionPage ? (isGkPage ? gks : offers) : [],
    extraTop3OffersEnabled: isSuggestionPage ? false : extraTop3,
    extraTop3SuggestionsEnabled: isSuggestionPage ? extraTop3 : false,
  };
}

export function parseSuggestionPage(data: IOfferSerialized[]): IOfferSource[] {
  return data.map((offer, index) => {
    const detailOffer = parseOfferDetail(offer, parseUrlParams(offer), index);

    return makeOfferSource(detailOffer, true, false);
  });
}

export function getExistingObjects(pages: IPage[], isGkList: boolean): Array<IGkSource | IOfferSource> {
  return flatMap(pages, (page): Array<IGkSource | IOfferSource> => {
    return [...(isGkList ? page.gks : page.offers), ...page.suggestions];
  });
}

function isNotTop3(object: IGkSource | IOfferSource): boolean {
  return object.type !== 'offer' || !object.data.baseInfo.isTop3;
}

export function getExistingOffersCount(pages: IPage[], isGkList: boolean): number {
  return flatMap(pages, page => {
    const list: (IGkSource | IOfferSource)[] = isGkList ? page.gks : page.offers;

    return list.filter(source => !page.extraTop3OffersEnabled || isNotTop3(source));
  }).length;
}

export function getExistingSuggestionsCount(pages: IPage[]): number {
  return flatMap(pages, page =>
    page.suggestions.filter(source => !page.extraTop3SuggestionsEnabled || isNotTop3(source)),
  ).length;
}

export function isSpecificKpList(jsonQuery: IJsonQuery): boolean {
  const { kp_id } = jsonQuery;

  return Boolean(kp_id && kp_id.value);
}

export function isSpecificGkList(jsonQuery: IJsonQuery, isNewobjectOnly: boolean): boolean {
  const { geo } = jsonQuery;

  return (
    // 1. Если в json_query.geo есть newobject и он один, значит это выдача в конкретном ЖК
    (!!geo && geo.value.filter(item => item.type === 'newobject').length === 1) ||
    // 2. Если 1 пункт false, проверяем на наличие multi_id в параметрах url — если он есть, это ЖК
    isNewobjectOnly
  );
}

export function isEmptyList(pages: IPage[], isGkList: boolean): boolean {
  return flatMap(pages, page => (isGkList ? page.gks : page.offers)).length === 0;
}

export function isCommercialList(jsonQuery: IJsonQuery): boolean {
  return ['commercialrent', 'commercialsale'].some(t => t === jsonQuery._type);
}

export function decrementDescription(description: string | undefined): string | undefined {
  if (!description) {
    return description;
  }
  const found = description.match(/^[\w\s]+/);
  const count = found && Number(found[0].replace(/\s/, ''));
  if (!count) {
    return description;
  }

  return `${numberToPrettyString(count - 1)} ${declension(count - 1, ['объявление', 'объявления', 'объявлений'])}`;
}
