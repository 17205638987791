import { IUndergroundInfo } from '../../api/models/offer_card';
import { IUnderground } from '../common/types';

export function addConstructingInfo(
  addressUndergrounds: IUnderground[],
  geoUndergrounds: IUndergroundInfo[],
): IUnderground[] {
  return addressUndergrounds.map(underground => {
    const similarFromGeo = geoUndergrounds.find(u => u.name === underground.name);
    if (similarFromGeo) {
      const { releaseYear, underConstruction } = similarFromGeo;

      return { ...underground, releaseYear, underConstruction };
    } else {
      return underground;
    }
  });
}

export function sortAndShortenUndergrounds(
  addressUndergrounds: IUnderground[] | undefined,
  geoUndergrounds: IUndergroundInfo[] | undefined,
  requestedUndergroundIds?: number[],
): IUnderground[] | undefined {
  const resultUndergrounds = addressUndergrounds
    ? addConstructingInfo(addressUndergrounds, geoUndergrounds || [])
    : undefined;

  if (!resultUndergrounds) {
    return;
  }

  if (requestedUndergroundIds) {
    resultUndergrounds.sort(sortUndergroundsByRequestedIds(requestedUndergroundIds));
  }

  return resultUndergrounds.slice(0, 1);
}

/**
 * Перемещает в начало списка станции метро, если они есть в поисковом запросе
 */
function sortUndergroundsByRequestedIds(
  requestedUndergroundIds: number[],
): (a: IUnderground, b: IUnderground) => number {
  return (a: IUnderground, b: IUnderground): number => {
    const aInRequestedUndergroundIds = a.id ? requestedUndergroundIds.includes(a.id) : false;
    const bInRequestedUndergroundIds = b.id ? requestedUndergroundIds.includes(b.id) : false;

    if (aInRequestedUndergroundIds && !bInRequestedUndergroundIds) {
      return -1;
    } else if (!aInRequestedUndergroundIds && bInRequestedUndergroundIds) {
      return 1;
    }

    return 0;
  };
}
